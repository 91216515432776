import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Banner from "../components/Banner"
import SEO from "../components/SEO"

const errorPage = () => {
  return (
    <Layout>
      <SEO title="404" keywords={[`error page`]} />
      <Header>
        <Banner title="404" info="Ooops wrong way">
          <AniLink slide to="/" className="btn-white">
            Back to home
          </AniLink>
        </Banner>
      </Header>
    </Layout>
  )
}

const Header = styled.div`
  background: rgba(26, 86, 208, 0.5);
  min-height: calc(100vh - 62px);
  display: flex;
  justify-content: center;
  align-items: center;
`

export default errorPage
